import {
  Alert,
  AlertIcon,
  Box,
  Button,
  Card,
  CardBody,
  CardHeader,
  Flex,
  FormControl,
  FormErrorMessage,
  Heading,
  IconButton,
  Input,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  Tooltip,
  Wrap,
  WrapItem,
} from "@chakra-ui/react";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { CiImageOn } from "react-icons/ci";
import { FaTimes, FaUpload } from "react-icons/fa";
import { toast } from "react-toastify";
import Loader from "../../../utils/Loader/Loader";
import { getHostDomain } from "../../../utils/utils";
import Social from "../Social";
import Smtp from "../Smtp";
import { useForm } from "react-hook-form";

const Branding = ({ tab, activeTab, agency }) => {
  const baseUrl = getHostDomain();
  const [logo, setLogo] = useState(null);
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const {
    buttonColorScheme,
    handleCreateAgency,
    agencyData,
    handleBrandCreate,
    isPending,
    themeColor,
    agencyName,
    isValidate,
    isLoading,
    textColor,
  } = agency;

  const handleLogoChange = async (event) => {
    const file = event.target.files[0];
    const url = baseUrl + `/api/v1/agency/${agencyData?.id}/upload_logo`;
    const accessToken = localStorage.getItem("token");
    if (file) {
      setLogo(URL.createObjectURL(file));
      let data = new FormData();
      data.append("upload_agency_logo", file);
      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: url,
        headers: {
          Accept: "*/*",
          "User-Agent": "Thunder Client (https://www.thunderclient.com)",
          Authorization: `Bearer ${accessToken}`,
        },
        data: data,
      };

      axios
        .request(config)
        .then((response) => {
          toast.success("Logo uploaded successfully.");
        })
        .catch((error) => {
          toast.error("Something went wrong.");
          console.error(error);
        });
    }
  };

  const handleLogoRemove = () => {
    setLogo(null);
  };

  const defaultSmtp = {
    host: agency?.agencyData?.branding?.smtp?.host || "",
    port: agency?.agencyData?.branding?.smtp?.port || "",
    username: agency?.agencyData?.branding?.smtp?.username || "",
    password: agency?.agencyData?.branding?.smtp?.password || "",
    email: agency?.agencyData?.branding?.smtp?.email || "",
  };
  const defaultSocial = {
    twitter: agency?.agencyData?.branding?.social?.twitter || "",
    facebook: agency?.agencyData?.branding?.social?.facebook || "",
    linkedin: agency?.agencyData?.branding?.social?.linkedin || "",
    instagram: agency?.agencyData?.branding?.social?.instagram || "",
  };
  const brandingForm = useForm({
    defaultValues: {
      branding: {
        smtp: defaultSmtp,
        social: defaultSocial,
      },
    },
  });
  useEffect(() => {
    brandingForm.setValue("branding", {
      ...agency?.agencyData?.branding,
      smtp: { ...defaultSmtp },
      social: { ...defaultSocial },
    });

    if (agencyData?.id && agencyData?.branding?.logo_url) {
      const baseUrl = window.location.origin.includes("insighto.ai") || window.location.origin.includes("localhost") ? "https://uc.insighto.ai/" : window.location.origin
      const agencyLogoUrl = agencyData.branding.logo_url.includes(window.origin)
        ? agencyData.branding.logo_url
        : `${baseUrl}${agencyData.branding.logo_url}`;
      setLogo(agencyLogoUrl);
    }
  }, [agency?.agencyData]);
  const tabs = [
    {
      value: "Social",
      children: (
        <Social
          tab={"Social"}
          activeTab={activeTabIndex}
          agency={agency}
          brandingForm={brandingForm}
        />
      ),
    },
    {
      value: "SMTP",
      children: (
        <Smtp
          tab={"smtp"}
          activeTab={activeTabIndex}
          agency={agency}
          brandingForm={brandingForm}
        />
      ),
    },
  ];
  return (
    <Box border="1px" p="5" borderRadius="md" h="81vh" overflowY="auto">
      {isLoading ? (
        <Loader />
      ) : (
        <Flex direction={"column"} gap={4}>
          <Box>
            <Text fontSize="xl" mb={4}>
              Create Agency
            </Text>
            <Card variant={"elevated"}>
              <CardBody>
                <Flex gap={2} flexDirection={"column"}>
                  <Alert status="info">
                    <AlertIcon />
                    {
                      "Agency name will be used across the portal incl. title, emails instead of Insighto.ai name."
                    }
                  </Alert>
                  <Flex gap={2}>
                    <FormControl
                      isRequired
                      isInvalid={isValidate && !agencyName}
                      width={"50%"}
                    >
                      <Input
                        value={agency?.agencyName}
                        onChange={(e) => agency.setAgencyName(e.target.value)}
                        isRequired
                        variant="outline"
                        placeholder="Enter Agency name"
                      />
                      {!agency.agencyName && !agencyName && (
                        <FormErrorMessage>
                          {"Agency name is required"}
                        </FormErrorMessage>
                      )}
                    </FormControl>
                    <Button
                      colorScheme={buttonColorScheme}
                      onClick={handleCreateAgency}
                    >
                      {!agencyData ? "Create" : "Update"}
                    </Button>
                  </Flex>
                </Flex>
              </CardBody>
            </Card>
          </Box>

          <Box>
            <Text fontSize="xl" mb={4}>
              Brand Settings
            </Text>
            <Stack direction={"row"} gap={2}>
              <Card variant={"elevated"} width={"30%"}>
                <CardHeader>
                  <Heading size={"sm"}>Upload Logo</Heading>
                </CardHeader>
                <CardBody alignItems={"center"}>
                  <FormControl id="logo">
                    <Flex justifyContent={"center"} align="center">
                      {logo ? (
                        <Box position="relative" w="100px" h="100px">
                          <label htmlFor="file-upload">
                            <img
                              src={logo}
                              alt="Logo"
                              style={{
                                width: "100px",
                                height: "100px",
                                cursor: "pointer",
                              }}
                            />
                          </label>
                          <IconButton
                            icon={<FaTimes />}
                            size="xs"
                            position="absolute"
                            top="-10px"
                            right="-10px"
                            colorScheme={buttonColorScheme}
                            onClick={handleLogoRemove}
                            aria-label="Remove Logo"
                          />
                        </Box>
                      ) : (
                        <Box
                          w="100px"
                          h="100px"
                          borderWidth={1}
                          borderRadius="md"
                          display="flex"
                          alignItems="center"
                          justifyContent="center"
                          color="gray.500"
                        >
                          <label
                            htmlFor="file-upload"
                            style={{ cursor: "pointer" }}
                          >
                            <CiImageOn />
                          </label>
                        </Box>
                      )}
                      <Input
                        id="file-upload"
                        type="file"
                        accept="image/*"
                        onChange={handleLogoChange}
                        display="none"
                        isDisabled={!agencyData}
                      />
                    </Flex>
                  </FormControl>
                </CardBody>
              </Card>
              <Card variant={"elevated"} width={"70%"}>
                <CardHeader>
                  <Heading size={"sm"}>Color Preset</Heading>
                </CardHeader>
                <CardBody>
                  <Wrap spacing="15px">
                    {agency.predefinedColors.map((color, index) => (
                      <WrapItem
                        key={color.name}
                        outline={
                          themeColor === color.color
                            ? `2px solid ${color.color}`
                            : ""
                        }
                        outlineOffset={themeColor === color.color ? "2px" : ""}
                        borderRadius={themeColor === color.color ? "5px" : ""}
                      >
                        <Tooltip label={color.name} key={color.name + index}>
                          <Box
                            w="24px"
                            h="24px"
                            bg={color.color}
                            cursor="pointer"
                            onClick={() => agency.setThemeColor(color.color)}
                            borderRadius="md"
                            _hover={{ opacity: 0.8 }}
                          />
                        </Tooltip>
                      </WrapItem>
                    ))}
                  </Wrap>
                </CardBody>
              </Card>
            </Stack>
          </Box>
          <Flex justifyContent={"flex-end"}>
            <Button
              colorScheme={buttonColorScheme}
              isDisabled={!agencyData}
              isLoading={isPending}
              onClick={handleBrandCreate}
            >
              {"Create Brand"}
            </Button>
          </Flex>
          <Box>
            <Tabs
              isLazy
              index={activeTabIndex}
              onChange={(index) => setActiveTabIndex(index)}
            >
              <TabList>
                {tabs.map((tab, listIndex) => (
                  <Tab
                    key={listIndex}
                    isDisabled={!agencyData && tab.value !== "Branding"}
                    color={textColor}
                  >
                    {tab.value}
                  </Tab>
                ))}
              </TabList>

              <TabPanels>
                {tabs.map((tab, panelIndex) => (
                  <TabPanel key={panelIndex}>
                    <Box mt={4}>{tab.children}</Box>
                  </TabPanel>
                ))}
              </TabPanels>
            </Tabs>
          </Box>
        </Flex>
      )}
    </Box>
  );
};

export default Branding;
